var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "pt-1 pb-0" }, [
      _c("span", { staticClass: "device-text table-overflow-elipses" }, [
        _c("strong", [_vm._v("Truck: " + _vm._s(_vm.name) + " ")])
      ])
    ]),
    _c("div", { staticClass: "pb-0 mt-0" }, [
      _vm.icon
        ? _c(
            "div",
            [
              _c("CIcon", {
                staticClass: "icon__style mt-2",
                attrs: { name: _vm.icon, size: "custom" }
              })
            ],
            1
          )
        : _vm._e(),
      _c("span", { staticClass: "table-overflow-elipses d-none d-sm-block" }, [
        _c("small", { staticClass: "device-label" }, [
          _vm._v(_vm._s(_vm.device))
        ])
      ])
    ]),
    _vm.showStatus
      ? _c("div", {}, [
          _c(
            "span",
            { staticClass: "table-overflow-elipses status-badge-bar  mt-0 " },
            [
              _c(
                "span",
                { staticClass: "status-badge-container" },
                [
                  _c("ConnectionStatusBadge", {
                    attrs: { value: _vm.getOnlineState() }
                  })
                ],
                1
              )
            ]
          ),
          _c("span", { staticClass: "table-overflow-elipses ml-1 mt-0 " }, [
            _c(
              "span",
              { staticClass: "status-badge-container" },
              [_c("TruckStatusBadge", { attrs: { value: _vm.status } })],
              1
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }