<template>
    <div>
        <div class="pt-1 pb-0">
            <span class="device-text table-overflow-elipses">
                <strong>Truck: {{ name }} </strong>
            </span>
        </div>

        <div class="pb-0 mt-0">
            <div v-if="icon">
                <CIcon :name="icon" class="icon__style mt-2" size="custom" />
            </div>
            <span class="table-overflow-elipses d-none d-sm-block"><small class="device-label">{{ device }}</small></span>
        </div>
        <div class="" v-if="showStatus">
            <span class="table-overflow-elipses status-badge-bar  mt-0 ">
                <span class="status-badge-container">
                    <ConnectionStatusBadge  :value="getOnlineState()"></ConnectionStatusBadge>
                </span></span>
                <span class="table-overflow-elipses ml-1 mt-0 "><span class="status-badge-container">
                    <TruckStatusBadge :value="status"></TruckStatusBadge>
                </span>
                </span>

        </div>
    </div>
</template>

<style scoped>
.device-text {
    word-wrap: normal;
}

.icon-container {
    margin: auto;
    padding: 1rem;
    max-height: 20px;
}

.icon__style {
    max-height: 50px;
    min-height: 20px;
}

.device-label{
    font-size: 10px;
    margin-top: 0px;
    padding: 0px;
    color: var(--gray)
}

.status-badge-bar{
   margin: auto 0; 
}
.status-badge-container{
    padding-top: 0px;
    padding: 0px;
    margin-left: 0px;
    padding-bottom: 1px;
}
</style>
<script>


import TruckStatusBadge from '@/components/badges/TruckStatusBadge.vue';
import ConnectionStatusBadge from '@/components/badges/ConnectionStatusBadge.vue';


export default {
    name: "TruckInfoCard",
    components: {
        TruckStatusBadge,
        ConnectionStatusBadge
    },
    props: {
        name: {
            type: String
        },
        device: {
            type: String
        },
        icon: {
            type: String,
            default: "cil-truck"
        },
        connected: {
            type: String,
            default: ""
        },
        showStatus:{
            type: Boolean, 
            default: true, 
        }, 
        status: {
            type: String, 
            default: ""
        }
    },
    methods: {
        getFleetStatus(){
            return this.status; 
        }, 
        getOnlineState() {
            if (this.connected === "online") {
                return "online";
            } else {
                return "offline";
            }
        }
    }
};
</script>
