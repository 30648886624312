<template>
  <CBadge :color="getColor()">
    {{ getText() }}
  </CBadge>
</template>
<style scoped>
.message {
  min-width: 100%;
  width: 100%;
}
.timestamp {
  min-width: 100%;
  width: 100%;
}
.status-badge-container {
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 50%;
  padding: 1px;
  padding-top: 3px;
}
.status-badge {
  padding: 0.5rem;
  border-radius: 15px;
  text-align: center;
  font-size: 14px;
  min-width: 100px;
}
</style>

<script>
import hydrovac_lib from "@/lib/hydrovac_lib/index";
import statusHelper from "@/lib/hydrovac_lib/statusHelper";

/** Description: 
 * Component to display the current truck status
 * Requires fleet status Library  
 */
export default {
  name: "TruckStatusBadge",
  props: {
    value: {
      type: String,  // Expects Object of Fleet Status 
    },
    text: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      
    }
  },
  computed: {
    status() {
      return this.value;
    }
  },
  methods: {
    getText() {
      // If User supplies text, just display that text
      if (this.text) {
        return this.text;
      } 
       else {
        return this.getLabel();
      }
    },
    getLabel() {
      let label = this.status;
      switch (this.status) {
        default:
          label = this.status;
          break;
        case "stopped":
          label = "stopped";
          break;
        case "working":
          break;
        case "idle":
          label = "idle";
          break;
      }
      return label;
    },
    getColor() {
      let color = "";
      switch (this.status) {
        case "idle":
          color = "warning";
          break;
        case("working"):
        case "moving":
          color = "success";
          break;
        case "stopped":
          color = "secondary";
          break;
      }
      return color;
    }
  }
};
</script>
